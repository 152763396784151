import { useEffect } from "react";

const UptimeTracker = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://uptime.cordes-hosting.net/tracker.js";
    script.async = true;
    script.setAttribute("data-website-id", "cm7estkrd0c0ijcfuu30dbj80");

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default UptimeTracker;
