import React, { useState, useEffect } from "react";
import Logo from "../../assets/img/logo.webp";
import LogoSmall from "../../assets/img/icon_gray.webp";
import { FaTimes } from "react-icons/fa";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import DropdownHosting from "./functions/dropdownHosting";
import DropdownAboutUs from "./functions/dropdownAboutUs";
import { RiMenuFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Navbar = () => {
  const { t } = useTranslation();

  console.dir(i18next.t("register.numbers"));

  const [isOpen, setIsOpen] = useState(false);
  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [isToggled1, setIsToggled1] = useState(false);
  const [isToggled2, setIsToggled2] = useState(false);

  const dropdownVariants = {
    closed: {
      scaleY: 0,
      opacity: 0,
      transition: { duration: 0.3 },
    },
    open: {
      scaleY: 1,
      opacity: 1,
      transition: { duration: 0.3 },
    },
    exit: {
      scaleY: 0,
      opacity: 0,
      transition: { duration: 0.3 },
      height: 0,
    },
  };

  const toggleIcon1 = () => {
    setIsToggled1(!isToggled1);
  };

  const toggleIcon2 = () => {
    setIsToggled2(!isToggled2);
  };

  const toggleDropdown1 = () => {
    setDropdown1(!dropdown1);
  };
  const toggleDropdown2 = () => {
    setDropdown2(!dropdown2);
  };

  useEffect(() => {
    if (isOpen) {
      // Verhindern des Scrollens auf der Hauptseite, wenn das Menü geöffnet ist
      document.body.style.overflow = "hidden";
    } else {
      // Scrollen wieder ermöglichen, wenn das Menü geschlossen ist
      document.body.style.overflow = "auto";
    }
  }, [isOpen]); // Abhängigkeit von isOpen

  return (
    <div className="">
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={() => setIsOpen(false)}
        ></div>
      )}
      <div className="flex font-bold md:text-sm text-[12px] md:justify-end justify-center md:pr-32 pr-0 text-gray-800 items-center w-full h-18 md:h-10 drop-shadow-2xl">
        <div className="flex flex-col md:flex-row items-center md:gap-8 gap-3 mt-1">
          <a
            className="flex flex-row gap-2 items-center hover:text-purple-600 duration-200"
            href="tel:+497214807690"
          >
            <AiOutlinePhone
              className="font-normal"
              style={{ fontSize: "20px" }}
            />
            <p>+49 (0) 721 4807690</p>
          </a>
          <a className="flex flex-row gap-2 items-center hover:text-purple-600 duration-200">
            <AiOutlineMail
              className="font-normal"
              style={{ fontSize: "20px" }}
            />
            <a href="mailto:vertrieb@cordes-hosting.net">
              vertrieb@cordes-hosting.de
            </a>
          </a>
        </div>
      </div>
      <div className="shadow-md w-full flex flex-row h-[70px] justify-around p-3 pl-6 pr-6 !items-center z-10">
        <div className="flex justify-start items-center">
          <div>
            <Link to="/">
              <img alt="Logo" src={Logo} className="w-40 max-[929px]:hidden" />
            </Link>
          </div>
          <div className="">
            <Link to="/">
              <img
                alt="Logo"
                src={LogoSmall}
                className="w-12 min-[930px]:hidden"
              />
            </Link>
          </div>
        </div>
        <div className="">
          <ul className="md:flex hidden flex-row justify-center items-center gap-2 md:flex font-bold text-gray-800">
            <Link to="/">
              {" "}
              <li className="p-3 text-sm hover:text-purple-600 hover:underline underline-offset-[13px] duration-300 decoration-[2px] rounded-full">
                {i18next.t("navbar.navbar_menu.home")}
              </li>
            </Link>
            <Link to="/blog">
              {" "}
              <li className="p-3 text-sm hover:text-purple-600 hover:underline underline-offset-[13px] duration-300 decoration-[2px] rounded-full">
                {i18next.t("navbar.navbar_menu.news")}
              </li>
            </Link>
            <Link to="/support">
              <li className="p-3 text-sm hover:text-purple-600 hover:underline underline-offset-[13px] duration-300 decoration-[2px] rounded-full">
                {i18next.t("navbar.navbar_menu.support")}
              </li>
            </Link>
            <DropdownAboutUs className="p-3 text-sm hover:text-purple-600 hover:underline underline-offset-[13px] duration-300 decoration-[2px] rounded-full" />
            <DropdownHosting className="p-3 text-sm hover:text-purple-600 hover:underline underline-offset-[13px] duration-300 decoration-[2px] rounded-full" />
          </ul>
        </div>
        <div className="flex flex-row gap-1">
          <Link to="/register">
            <motion.li
              whileHover={{
                scale: 1.1,
                transition: 0.03,
              }}
              whileTap={{
                scale: 0.9,
                transition: 0.03,
              }}
              transition={{ ease: "easeOut", duration: 0.03 }}
              href="#"
              data-tianji-event="Register Button clicked!"
              className="hidden md:inline-block bg-[#3D73F5] cursor-pointer p-1.5 pl-3 pr-3 rounded-3xl hover:bg-[#4169FC] duration-300 text-white font-bold"
            >
              {i18next.t("navbar.navbar_menu.register")}
            </motion.li>
          </Link>
          <motion.a
            whileHover={{
              scale: 1.1,
              transition: 0.03,
            }}
            whileTap={{
              scale: 0.9,
              transition: 0.03,
            }}
            transition={{ ease: "easeOut", duration: 0.03 }}
            href="https://dashboard.cordes-hosting.net/"
            target="_blank"
            data-tianji-event="Login Button geklickt!"
            className="hidden md:inline-block bg-[#3D73F5] cursor-pointer p-1.5 pl-3 pr-3 rounded-3xl hover:bg-[#4169FC] duration-300 text-white font-bold"
          >
            {i18next.t("navbar.navbar_menu.login")}
          </motion.a>
        </div>
        <div className="md:hidden">
          <button onClick={() => setIsOpen(!isOpen)} className="text-3xl mt-2">
            <RiMenuFill className="md:hidden flex" />
          </button>
          <div
            className={`${
              isOpen ? "translate-x-0" : "translate-x-full"
            } md:hidden fixed right-0 top-0 max-[276px]:!w-[170px] !w-[250px] h-full bg-white p-5 transition-transform card-background z-40`}
          >
            <div className="flex items-center justify-center pb-8">
              <img alt="Logo" src={Logo} className="w-40" />
            </div>
            <div>
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="text-xl pb-8"
              >
                <FaTimes className="text-gray-800" />
              </button>
            </div>
            <ul className="flex flex-col">
              <Link to="/">
                <li className="py-2 font-bold">
                  {i18next.t("navbar.navbar_menu.home")}
                </li>
              </Link>
              <Link to="/support">
                <li className="py-2 font-bold cursor-pointer">
                  {i18next.t("navbar.navbar_menu.support")}
                </li>
              </Link>
              <Link to="/blog">
                <li className="py-2 font-bold cursor-pointer">
                  {i18next.t("navbar.navbar_menu.news")}
                </li>
              </Link>
              <li className="py-2">
                <div className="flex flex-row items-center">
                  <button
                    onClick={() => {
                      toggleDropdown1();
                      toggleIcon1();
                    }}
                    className="font-bold"
                  >
                    {i18next.t("navbar.navbar_menu.aboutus")}
                  </button>
                  <motion.div
                    animate={{ rotate: isToggled1 ? 90 : 0 }}
                    transition={{ duration: 0.25 }}
                    style={{ display: "inline-block" }}
                  >
                    <IoIosArrowForward className="pl-2 w-[20px] h-[20px] text-gray-800" />
                  </motion.div>
                </div>
                <AnimatePresence>
                  {dropdown1 && (
                    <motion.div
                      initial="closed"
                      animate={dropdown1 ? "open" : "closed"}
                      exit="exit"
                      className="p-1"
                      variants={dropdownVariants}
                      style={{ originY: 0 }}
                    >
                      <ul>
                        <Link to="/aboutus">
                          <li className="p-2">
                            {i18next.t(
                              "navbar.navbar_menu.aboutus_menu.company"
                            )}
                          </li>
                        </Link>
                        <Link to="/rz">
                          <li className="p-2">
                            {i18next.t(
                              "navbar.navbar_menu.aboutus_menu.datacenter"
                            )}
                          </li>
                        </Link>
                        <Link to="/productPort">
                          <li className="p-2">
                            {i18next.t(
                              "navbar.navbar_menu.aboutus_menu.productport"
                            )}
                          </li>
                        </Link>
                      </ul>
                    </motion.div>
                  )}
                </AnimatePresence>
              </li>
              <li className="py-2">
                <div className="flex flex-row items-center">
                  <button
                    onClick={() => {
                      toggleDropdown2();
                      toggleIcon2();
                    }}
                    className="font-bold"
                  >
                    {i18next.t("navbar.navbar_menu.hosting")}
                  </button>
                  <motion.div
                    animate={{ rotate: isToggled2 ? 90 : 0 }}
                    transition={{ duration: 0.25 }}
                    style={{ display: "inline-block" }}
                  >
                    <IoIosArrowForward className="pl-2 w-[20px] h-[20px] text-gray-800" />
                  </motion.div>
                </div>
                <AnimatePresence>
                  {dropdown2 && (
                    <motion.div
                      initial="closed"
                      animate={dropdown2 ? "open" : "closed"}
                      exit="exit"
                      className="p-1"
                      variants={dropdownVariants}
                      style={{ originY: 0 }}
                    >
                      <ul>
                        <Link to="/reselling">
                          <li className="p-2">
                            {i18next.t(
                              "navbar.navbar_menu.hosting_menu.reselling"
                            )}
                          </li>
                        </Link>
                        <Link to="/technic">
                          <li className="p-2">
                            {i18next.t(
                              "navbar.navbar_menu.hosting_menu.technic"
                            )}
                          </li>
                        </Link>
                        <Link to="/domains">
                          <li className="p-2">
                            {i18next.t(
                              "navbar.navbar_menu.hosting_menu.domains"
                            )}
                          </li>
                        </Link>
                        <Link to="/status">
                          <li className="p-2">
                            {i18next.t(
                              "navbar.navbar_menu.hosting_menu.serverstatus"
                            )}
                          </li>
                        </Link>
                      </ul>
                    </motion.div>
                  )}
                </AnimatePresence>
              </li>
              <div className="w-full text-center border-b border-gray-400 pb-3"></div>
              <Link to="/register">
                <li className="py-2 pt-3 font-bold cursor-pointer">
                  {i18next.t("navbar.navbar_menu.register")}
                </li>
              </Link>

              <a
                className="py-2 font-bold cursor-pointer"
                href="https://dashboard.cordes-hosting.net/"
                target="_blank"
              >
                {i18next.t("navbar.navbar_menu.login")}
              </a>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
