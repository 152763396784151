import React, { useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import Swal from "sweetalert2";
import i18next from "i18next";

const MainContact = () => {
  const [formDataMail, setFormDataMail] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormDataMail((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRegistration = async (event) => {
    handleInputChange(event);
    event.preventDefault();

    // Zeigt die Ladeanimation an
    Swal.fire({
      title: i18next.t("contact.email_send"),
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const response = await fetch(
        "https://flow.cordes-hosting.net/webhook/b395f988-959e-4607-bc39-66f567158f80",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formDataMail),
        }
      );

      // Schließt die Ladeanimation
      Swal.close();

      if (response.status === 200) {
        Swal.fire({
          title: i18next.t("contact.email_success"),
          text: i18next.t("contact.email_success_text"),
          icon: "success",
          timer: 4000,
          timerProgressBar: true,
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          willClose: () => {
            window.location.href = `https://cordes-hosting.de/`;
          },
        });
      } else if (response.status === 500) {
        Swal.fire(i18next.t("contact.email_fail"), "", "error");
      }
    } catch (error) {
      // Schließt die Ladeanimation im Fehlerfall
      Swal.close();
      console.error("Error:", error);
      Swal.fire(i18next.t("contact.email_fail"), "", "error");
    }
  };

  return (
    <div className="flex justify-center items-center w-full flex-col pt-10 pb-10 duration-500">
      <div className="text-center md:pb-12 pb-10">
        <h1 className="md:text-[40px] text-[30px] text-gray-800 font-bold">
          {i18next.t("contact.main_text")}
        </h1>
      </div>
      <form
        onSubmit={handleRegistration}
        className="border-gray-500 rounded-xl md:w-2/3 w-[90vw]"
      >
        <div className="flex md:flex-row flex-col justify-center md:gap-6 md:pr-0 md:pl-0 pr-6 pl-6">
          <div className="relative mt-8 w-full">
            <input
              type="text"
              id="firstname"
              aria-describedby="filled_success_help"
              className="rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer duration-100"
              required
              name="firstname"
              onChange={handleInputChange}
              placeholder=" "
            />
            <label
              htmlFor="firstname"
              className="absolute text-sm text-gray-600 dark:text-gray-200 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
            >
              {i18next.t("contact.firstname")}
            </label>
          </div>
          <div className="relative mt-8 w-full">
            <input
              type="text"
              id="lastname"
              aria-describedby="filled_success_help"
              className="rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer duration-100"
              required
              name="lastname"
              onChange={handleInputChange}
              placeholder=" "
            />
            <label
              htmlFor="lastname"
              className="absolute text-sm text-gray-600 dark:text-gray-200 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
            >
              {i18next.t("contact.lastname")}
            </label>
          </div>
          <div className="relative mt-8 w-full">
            <input
              type="email"
              id="mail"
              aria-describedby="filled_success_help"
              className="rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer duration-100"
              required
              minLength="5"
              name="mail"
              onChange={handleInputChange}
              placeholder=" "
            />
            <label
              htmlFor="mail"
              className="absolute text-sm text-gray-600 dark:text-gray-200 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
            >
              {i18next.t("contact.email_adress")}
            </label>
          </div>
        </div>
        <div className="relative mt-8 w-full">
          <textarea
            id="message"
            aria-describedby="filled_success_help"
            className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer duration-100"
            required
            name="message"
            onChange={handleInputChange}
            placeholder="Bitte geben Sie Ihr Anliegen ein.."
            rows="6"
          ></textarea>
        </div>
        <div className="flex justify-center mt-8 w-full">
          <motion.button
            whileHover={{
              scale: 1.1,
              transition: 0.03,
            }}
            whileTap={{
              scale: 0.9,
              transition: 0.03,
            }}
            transition={{ ease: "easeOut", duration: 0.03 }}
            href="#"
            className="inline-block bg-[#3D73F5] p-3.5 md:pl-16 md:pr-16 pl-8 pr-8 rounded-3xl hover:bg-[#4169FC] duration-300 text-white font-bold"
          >
            {i18next.t("contact.button_send_text")}
          </motion.button>
        </div>
      </form>
    </div>
  );
};

export default MainContact;
