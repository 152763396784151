import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "tailwindcss/tailwind.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const UptimeDisplay = () => {
  const [groupedChartData, setGroupedChartData] = useState({});

  const formatDataForChart = (serverData) => {
    const timestamps = serverData.statusData.map((entry) => entry.time);
    const values = serverData.statusData.map((entry) => entry.status);

    return {
      serverName: serverData.name,
      labels: timestamps,
      datasets: [
        {
          label: serverData.name,
          backgroundColor: values.map((value) => {
            return value === 1
              ? "rgba(33,204,106,.8)"
              : value === 0
              ? "rgba(221,73,55,.8)"
              : "rgba(58, 87, 232, .8)";
          }),
          borderColor: "rgba(0, 0, 0, 0)",
          borderWidth: 1,
          minBarLength: 100,
          data: values,
        },
      ],
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://status.uptime.cordes-hosting.net/status",
          {
            headers: new Headers({
              Authorization:
                "Bearer sk_51d4433018b92921c2c08ff97c4cf3f873a74a588fb6a614c7659f9a4b73365c",
            }),
          }
        );
        const data = await response.json();
        const groupedData = {};

        // Verarbeiten der neuen API-Struktur
        Object.entries(data).forEach(([groupName, serverArray]) => {
          groupedData[groupName] = serverArray.map(formatDataForChart);
        });

        setGroupedChartData(groupedData);
      } catch (error) {
        console.error("Fehler beim Abrufen der Daten:", error);
      }
    };

    fetchData();
  }, []);

  // Formatierung der Zeitstempel
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000); // Unix-Zeitstempel in Millisekunden umwandeln
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Monate sind 0-basiert
    const year = date.getFullYear();
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const options = {
    animation: false,
    maintainAspectRatio: false,
    barPercentage: 0.9,
    categoryPercentage: 1,
    scales: {
      x: {
        display: false,
        ticks: {
          callback: (val) => formatTimestamp(val), // Formatierte Zeitstempel anzeigen
        },
        title: {
          display: true,
          text: "Zeit",
        },
      },
      y: {
        display: false,
        title: {
          display: true,
          text: "Wert",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const formattedDate = formatTimestamp(context.label); // Formatierte Zeit im Tooltip
            return `${formattedDate} => ${
              context.parsed.y === 1
                ? "UP"
                : context.parsed.y === 0
                ? "DOWN"
                : "MAINTENANCE"
            }`;
          },
          title: function () {
            return "";
          },
        },
      },
    },
  };

  return (
    <div className="p-4 min-h-screen">
      <h1 className="custom-h1 mb-8">Server-Status</h1>
      {Object.entries(groupedChartData).map(
        ([groupName, charts], groupIndex) => (
          <div key={groupIndex} className="mb-6">
            <div className="card-background-resource bg-opacity-25 p-5 rounded-lg shadow-lg">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                {groupName}
              </h2>
              {charts.map((chartData, index) => (
                <div key={index} className="mb-4">
                  <h3 className="text-md font-semibold text-gray-800 mb-2">
                    {chartData.serverName}
                  </h3>
                  <div className="!h-[30px] bg-transparent rounded-lg overflow-hidden relative">
                    <Bar
                      data={chartData}
                      className="absolute h-full top-0 left-0 w-full"
                      options={options}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default UptimeDisplay;
